import * as React from 'react';
import styled from '../../theme';

const Container = styled.div`
  display: none;
  @media all and (min-width: 50em) {
    position: relative;
    display: block;
    right: 0;
    width: 310px;
    margin-top: 0;
  }
`;

const FluidImage = styled.img`
  width: 100%;
  max-width: 100%;
  position: relative;
  height: auto;
  @media all and (min-width: 50em) {
    width: 100%;
  }
`;

interface Props {
  url: string;
  srcSet: string;
  alt?: string;
}

const InternalImage: React.SFC<Props> = ({ url, srcSet, alt }) => (
  <Container>
    <FluidImage src={url} srcSet={srcSet} alt={alt} />
  </Container>
);

export default InternalImage;
