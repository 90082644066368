import * as React from 'react';
import styled, { theme } from '../../theme';
import { splitSentence } from '../../lib/utils';

const Container = styled.section`
  margin-left: auto;
  margin-right: auto;
  @media all and (min-width: 50em) {
    width: 100%;
  }
`;

const PaddingDiv = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 60px 40px;
  padding-bottom: 5px;
  position: relative;
  z-index: 10;
  @media all and (min-width: 50em) {
    padding-left: 20vw;
    padding-right: 20vw;
    flex-direction: row;
  }
`;

const InnerContainer = styled.div`
  position: relative;
  width: 100%;
  @media all and (min-width: 50em) {
    width: 45%;
    max-width: 480px;
  }
`;

const SectionTitle = styled.h2`
  margin: 0;
  color: #1c1b20;
  margin-bottom: 20px;
  text-transform: none;
  line-height: 1.1;
  font-size: 4rem;
  font-weight: 900;
  display: flex;
  flex-direction: column;
  @media all and (min-width: 50em) {
    font-size: 5rem;
  }
`;

const SectionTitleEm = styled.span`
  font-weight: 400;
  font-style: italic;
`;

interface DotProps {
  current: string;
  className?: string;
}

const D: React.SFC<DotProps> = ({ current, className }) => (
  <span className={className}>.</span>
);

const Dot = styled(D)`
  color: ${(props: DotProps) => theme[props.current] || theme.default};
`;

const Content = styled.p`
  width: 100%;
  margin-bottom: 50px;
`;

interface Props {
  title: string;
  current: string;
  content?: string[];
  children?: React.ReactChild | React.ReactChild[];
  innerChildren?: React.ReactChild | React.ReactChild[];
}

const Section: React.SFC<Props> = ({
  title,
  content = [],
  current,
  children,
  innerChildren,
}) => {
  const titleSplit = splitSentence(title);
  return (
    <Container>
      <PaddingDiv>
        <InnerContainer>
          <SectionTitle>
            {titleSplit[0] && titleSplit[0]}
            {titleSplit[1] && (
              <SectionTitleEm>
                {titleSplit[1]}
                <Dot current={current} />
              </SectionTitleEm>
            )}
          </SectionTitle>
          {Array.isArray(content) &&
            content.map((c: string) => <Content key={c}>{c}</Content>)}
        </InnerContainer>
        {innerChildren}
      </PaddingDiv>
      {children}
    </Container>
  );
};

export default Section;
