import * as React from 'react';
import { Link } from 'gatsby';
import styled, { theme } from '../theme';
import Layout from '../components/layout';
import Container from '../components/sections/Container';
import Intro from '../components/sections/Intro';
import Section from '../components/sections/Section';
import ImageSection from '../components/atoms/InternalImage';

const PaddingDiv = styled.div`
  padding: 60px 40px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & > span:not(:first-child) {
    margin-top: 10px;
  }
  @media all and (min-width: 50em) {
    padding: 10vh 15vw;
    & > span {
      font-size: 2rem;
    }
  }
`;

const StyledLink = styled(Link)`
  position: relative;
  display: inline-block;
  font-size: 1.6rem;
  font-style: italic;
  text-decoration: none;
  color: #1c1b20;
  background: none;
  border: none;
  &::after,
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    overflow: hidden;
    background: #1c1b20;
    z-index: 0;
    transform-origin: bottom-left;
    transition: transform 400ms cubic-bezier(1, 0, 0, 1) 0ms;
  }
  &::before {
    background: ${theme.about};
    bottom: 8px;
    top: 8px;
    height: auto;
    opacity: 0.5;
    left: -5px;
    right: -5px;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition-duration: 400ms;
  }
  &:hover::before {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
  &:hover::after {
    transform: scaleX(0);
    transform-origin: bottom right;
    transition-duration: 400ms;
  }
  @media all and (min-width: 50em) {
    font-size: 2rem;
  }
`;

const Anchor = styled.a`
  position: relative;
  display: inline-block;
  font-size: 1.6rem;
  font-style: italic;
  background: none;
  text-decoration: none;
  color: #1c1b20;
  transition: color 500ms cubic-bezier(1, 0, 0, 1) 0ms;
  &::after,
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    overflow: hidden;
    background: #1c1b20;
    z-index: 0;
    transform-origin: bottom-left;
    transition: transform 400ms cubic-bezier(1, 0, 0, 1) 0ms;
  }
  &::before {
    background: ${theme.about};
    bottom: 8px;
    top: 8px;
    height: auto;
    opacity: 0.5;
    left: -5px;
    right: -5px;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition-duration: 400ms;
  }
  &:hover::before {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
  &:hover::after {
    transform: scaleX(0);
    transform-origin: bottom right;
    transition-duration: 400ms;
  }
  @media all and (min-width: 50em) {
    font-size: 2rem;
  }
`;

interface Props {
  history?: History;
  location?: Location;
}

const AboutPage: React.SFC<Props> = ({ location }) => (
  <Layout>
    <Container current={location.pathname.substring(1)}>
      <Intro
        title="about me."
        subTitle="I build interactive web experiences that help bring
            brands to life and add depth to their stories."
      />
      <Section
        current={location.pathname.substring(1)}
        title="A little about me"
        content={[
          'My mission is to revolutionize the way brands are experienced by making online interactions meaningful and memorable.',
          'I use cutting edge web technology combined with pleasing and purposeful visuals to tell stories and invoke a feeling of connectedness.',
        ]}
        innerChildren={
          <ImageSection
            alt="Kyle Lutes Freelance Developer"
            srcSet="https://res.cloudinary.com/kyle-lutes/image/upload/c_scale,w_350,e_grayscale/v1526848157/upworkpic.jpg 375w, https://res.cloudinary.com/kyle-lutes/image/upload/c_scale,w_350,e_grayscale/v1526848157/upworkpic.jpg 1280w"
            url="https://res.cloudinary.com/kyle-lutes/image/upload/e_grayscale/v1526848157/upworkpic.jpg"
          />
        }
      />
      <Section current={location.pathname.substring(1)} title="My history..">
        <PaddingDiv>
          <span>
            Currently a Senior Web Developer at{' '}
            <Anchor
              href="https://omicronmedia.com/"
              rel="noopener noreferrer"
              target="_blank"
              aria-label="omicronmedia.com"
            >
              Omicron Media
            </Anchor>
          </span>
          <span>
            Previously an Application Developer at{' '}
            <Anchor
              href="https://www.belunar.com/"
              rel="noopener noreferrer"
              target="_blank"
              aria-label="belunar.com"
            >
              Lunar
            </Anchor>
          </span>
          <span>
            Previously a Backend Developer at{' '}
            <Anchor
              href="https://www.launchthat.com/"
              rel="noopener noreferrer"
              target="_blank"
              aria-label="launchthat.com"
            >
              LaunchThat
            </Anchor>
          </span>
          <StyledLink to="/work">Personal Projects</StyledLink>
        </PaddingDiv>
      </Section>
    </Container>
  </Layout>
);

export default AboutPage;
